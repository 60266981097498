import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addStudentToList } from '../../../reducers/emailSlice'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import TextField from '../../globalComponents/textFields/TextField'
import './addStudentPopupStyle.css'

const AddStudentPopup = ({ onClose, listId }) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const dispatch = useDispatch();

    const onSubmit = () => {
        console.log({obj: {name,email,phone},listId});
        if(name !== '' && email !== '' && phone !== '' ){
           dispatch(addStudentToList({obj: {name,email,phone},listId}));
        }
    }

    return (
        <div className="popupOuter active">
            <div className="uplaodcsvContainer">
                <div className="popupHeader">
                    <p className="popupTitle">Add Student </p>
                    <img onClick={onClose} src="/assets/icons/closeWhite.svg" alt="" className='pointer' />
                </div>
                <div className="popupBody">
                    <TextField labelName={'Name'} onChange={(e)=> setName(e.target.value)} placeholder={'Enter student name'} isImportant={true} /><br/>
                    <TextField labelName={'Email'} onChange={(e)=> setEmail(e.target.value)} placeholder={'Enter student email'} isImportant={true} /><br/>
                    <TextField labelName={'Phone'} onChange={(e)=> setPhone(e.target.value)} placeholder={'Enter student phone'} isImportant={true} /><br/>

                    <div className="formFooter">
                        <PrimaryButton classname={'primaryBtn'} children={'Submit'} onClick={onSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddStudentPopup