import React from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import CustomUploadBox from '../../globalComponents/customUploadBox/CustomUploadBox'
import TextField from '../../globalComponents/textFields/TextField'
import './UploadCSVPopupStyle.css'
import { CSVLink, CSVDownload } from "react-csv";
import PrimaryButtonImg from '../../globalComponents/buttons/primaryButtonImg/PrimaryButtonImg'

const UploadCSVPopup = ({ onClick, onUpload, onUploadClick, onListChange, onSampleCsv, csvFileDownload }) => {
    return (
        <div className="popupOuter active">
            <div className="uplaodcsvContainer">
                <div className="popupHeader">
                    <p className="popupTitle">Upload CSV </p>
                    <img onClick={onClick} src="/assets/icons/closeWhite.svg" alt="" className='pointer' />
                </div>
                <div className="popupBody">
                    <TextField labelName={'List Name'} onChange={onListChange} placeholder={'Write the List Name'} isImportant={true} />
                    <div className="formGroup mt-10 mb-10"><input type='file' onChange={onUpload} accept=".csv" /></div>
                    <div className="formFooter">
                        <PrimaryButton classname={'primaryBtn'} children={'Upload'} onClick={onUploadClick} />
                        <CSVLink
                            data={csvFileDownload ? csvFileDownload : ""}
                            target="_blank"
                            className="downloadBtn"
                            filename="SampleListCsv"
                        >
                            <img src="../assets/icons/downloadImg.svg" alt="" />
                            Download CSV
                        </CSVLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadCSVPopup