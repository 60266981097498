import React from 'react'
import './TextFieldStyle.css';

const TextField = ({labelName,inputType,placeholder,isImportant,name,value,onChange,min,onBlur, defaultValue}) => {
    return (
        <div className="formGroup dFlex gap5 flexColumn">
           {labelName &&  <label htmlFor="Email">
                {labelName}
                {isImportant? <span className='impMark'>*</span>:null }
            </label>}
            <input
                className="formInput"
                type={inputType}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                min={min}
                onBlur={onBlur}
                defaultValue={defaultValue}
            />
        </div>
    )
}

export default TextField