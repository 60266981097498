import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

export const loginUser = createAsyncThunk(
  "loginUser",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = {
        email: email,
        password: password,
      };

      console.log("nvnfjkvnfknvjfnfv fv f", payload);
      const response = await fetch(
        `${environment.baseURL}/api/loginUser`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        localStorage.setItem('token',data.Data?.token);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    email: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isUser = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      console.log("payload payload", payload);
      state.email = payload?.Data?.email;
      state.username = payload?.Data?.firstName;
      state.isFetching = false;
      state.isSuccess = true;
      state.isUser = payload?.isUser;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [loginUser.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;
