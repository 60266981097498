import React, { useState, useEffect } from 'react'
import { clearState, loginUser, userSelector } from '../../reducers/userSlice';
import './LoginStyle.css'
import { useDispatch, useSelector } from "react-redux";
import PageLoader from '../../components/globalComponents/PageLoader/PageLoader';
import { COMMON_TEXT } from '../../constants/textConstant';
import { useNavigate } from 'react-router-dom';


const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validateEmail, setEmailValidation] = useState(false);
  const [validatePassword, setPasswordValidation] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isFetching, isSuccess, isError, errorMessage } = useSelector(userSelector);

  /**VERIFY EMAIL ON TYPE */
  const onChangeEmail = (event) => {
    var mEmailAddress = event.target.value;
    setEmail(event.target.value);
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (mEmailAddress === "") {
      setEmailValidation(true);
    } else if (!filter.test(mEmailAddress)) {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
    }
  };

  /**VERIFY PASSWORD ON TYPE */
  const onChangePassword = (event) => {
    var mPassword = event.target.value;
    setPassword(event.target.value);
    if (mPassword === "") {
      setPasswordValidation(true);
    } else {
      setPasswordValidation(false);
    }
  };

  const onSignIn = (e) => {
    e.preventDefault();
    if (email && password) {
      var data = {
        email: email,
        password: password,
      };
      dispatch(loginUser(data));
    } else {
      // toast.error("Both fields must not be empty !");
      // setIsShowError(true);
      // setErrMessage("Both fields must not be empty !");
      // localStorage.removeItem("token");
    }
  };

  

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    let isAuth = localStorage.getItem("token");
    if (isAuth && isAuth !== "undefined" && isAuth !== "null") {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    // if (isError) {
    //   // toast.error(errorMessage);
    //   // setIsShowError(true);
    //   // setErrMessage(errorMessage);
    //   dispatch(clearState());
    // }

    if (isSuccess) {
      dispatch(clearState());
      localStorage.setItem("isLoggedIn", "true");
      navigate("/", { replace: true });
    }
  }, [ isSuccess]);


  return (
    <>
      {isFetching && <PageLoader />}
      <div className="loginContainer">
        <div className="loginContainerLeft">
          <img src="/assets/img/egronLoginImg.png" alt="" />
          
        </div>
        <div className="loginContainerRight">
          <img src="/assets/img/egronLogo.png" alt="" onClick={() => navigate('/')} style={{cursor: "pointer"}}/>
          <p className="welcomeText">Welcome Back</p>
          <p className="welcomeSubTitle">Welcome Back! please enter your Details.</p>
          <div className="loginFormContainer">
            <div className="formGroup">
              <label htmlFor="email">Email Id</label>
              <input className='loginInput' type="email" name="email" id="email" placeholder='john.doe@gmail.com' onChange={onChangeEmail} />
            </div>
            {validateEmail && (
              <span className="msg-error" id="nameValidation">
                {COMMON_TEXT.emailAlert}
              </span>
            )}
            <div className="formGroup mt5">
              <label htmlFor="password">Password</label>
              <input className='loginInput' type="password" name="password" id="password" placeholder='•••••••••' onChange={onChangePassword} />
            </div>
            {validatePassword && (
                    <span className="msg-error" id="passValidation">
                      {COMMON_TEXT.passwordAlert}
                    </span>
                  )}
            {/* <p className="frogetLink">Forget Password?</p> */}
            <button type='submit' className="signInButtton" onClick={onSignIn}>Sign in</button>
            {/* <button className="signInwthGoogle"> <img src="/assets/icons/googleIcon.svg" alt="" /> Sign up with Google</button> */}
            <div className="copyrightText">
              <p>Copyright </p> |
              <p>Privacy Policy</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login