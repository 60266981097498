import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environments/environment";

let token;

let setTokenValues = () => {
    token = localStorage.getItem("token");
};
export const getAllLists = createAsyncThunk(
    "emails/getAllLists",
    async (_, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                `${environment.baseURL}/api/getAllLists`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const getAllEmailRecords = createAsyncThunk(
    "emails/getAllEmailRecords",
    async (_, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                `${environment.baseURL}/api/getAllEmailRecords`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const downloadSampleEmailCSV = createAsyncThunk(
    "emails/downloadSampleEmailCSV",
    async (_, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                `${environment.baseURL}/api/downloadSampleEmailCSV`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            let data = await response.text();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getAllStudentsByList = createAsyncThunk(
    "emails/getAllStudentsByList",
    async (listId, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                `${environment.baseURL}/api/getAllStudentsByList/${listId}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getAllStudentsFromRecord = createAsyncThunk(
    "emails/getAllStudentsFromRecord",
    async (recordId, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                `${environment.baseURL}/api/getAllStudentsFromRecord/${recordId}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const getAllStudentsByUser = createAsyncThunk(
    "emails/getAllStudentsByUser",
    async (_, thunkAPI) => {
        try {
            setTokenValues();
            const response = await fetch(
                `${environment.baseURL}/api/getAllStudentsByUser`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data.Data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const addStudentsToList = createAsyncThunk(
    "emails/addStudentsToList",
    async ({ file, listName }, thunkAPI) => {
        try {
            setTokenValues();
            const formData = new FormData();
            formData.append("file", file);

            const response = await fetch(
                `${environment.baseURL}/api/addStudentsToList/${listName}`,
                {
                    method: "POST",
                    headers: {
                        //   Accept: "application/json",
                        //  "Content-Type": "multipart/form-data",
                        Authorization: token,
                    },
                    body: formData,
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const updateListStudents = createAsyncThunk(
    "emails/updateListStudents",
    async ({ stdIds, listId }, thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                `${environment.baseURL}/api/updateListStudents/${listId}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify({ stdIds }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const updateStudentData = createAsyncThunk(
    "emails/updateStudentData",
    async ( {obj,stdId} , thunkAPI) => {
        console.log({ obj,stdId });
        try {
            setTokenValues();

            const response = await fetch(
                `${environment.baseURL}/api/updateStudentData/${stdId}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify(obj),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const addStudentToList = createAsyncThunk(
    "emails/addStudentToList",
    async ({ obj, listId }, thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                `${environment.baseURL}/api/addStudentToList/${listId}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify(obj),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const sendMailToStudents = createAsyncThunk(
    "emails/sendMailToStudents",
    async ({ subject, listId, emailBody }, thunkAPI) => {
        try {
            setTokenValues();

            const response = await fetch(
                `${environment.baseURL}/api/sendMailToStudents/${listId}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify({ subject, htmlBody: emailBody }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const emailSlice = createSlice({
    name: "emailAuto",
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        mailSent: false,
        errorMessage: "",
        sampleFileDownload: "",
        listData: [],
        students: [],
        studentsList: [],
        studentsLists: null,
        emailRecords: [],
        uploaded: false,
        saved: false,
        studentAdded: false,
        updated: false
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.mailSent = false;
            return state;
        },
    },
    extraReducers: {
        [getAllLists.fulfilled]: (state, { payload }) => {
            state.listData = payload;
            state.isFetching = false;
            return state;
        },
        [getAllLists.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllLists.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [addStudentsToList.fulfilled]: (state, { payload }) => {
            state.uploaded = !state.uploaded;
            state.isFetching = false;
            return state;
        },
        [addStudentsToList.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [addStudentsToList.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [updateListStudents.fulfilled]: (state, { payload }) => {
            state.saved = !state.saved;
            state.isFetching = false;
            return state;
        },
        [updateListStudents.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [updateListStudents.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [sendMailToStudents.fulfilled]: (state, { payload }) => {
            state.mailSent = true;
            state.isFetching = false;
            return state;
        },
        [sendMailToStudents.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [sendMailToStudents.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [getAllStudentsByList.fulfilled]: (state, { payload }) => {
            console.log("printing post payload", payload);
            state.studentsList = payload;
            state.isFetching = false;
            return state;
        },
        [getAllStudentsByList.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllStudentsByList.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [downloadSampleEmailCSV.fulfilled]: (state, { payload }) => {
            console.log("printing payload", payload);
            state.sampleFileDownload = payload;
            // state.isFetching = false;
            return state;
        },
        [downloadSampleEmailCSV.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [downloadSampleEmailCSV.rejected]: (state, { payload }) => {
            console.log("payload", payload);
            // state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllEmailRecords.fulfilled]: (state, { payload }) => {
            console.log("printing payload", payload);
            state.emailRecords = payload;
            state.isFetching = false;
            return state;
        },
        [getAllEmailRecords.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [getAllEmailRecords.rejected]: (state, { payload }) => {
            console.log("payload", payload);
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllStudentsByUser.fulfilled]: (state, { payload }) => {
            console.log("printing payload", payload);
            state.students = payload;
            state.isFetching = false;
            return state;
        },
        [getAllStudentsByUser.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [getAllStudentsByUser.rejected]: (state, { payload }) => {
            console.log("payload", payload);
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [addStudentToList.fulfilled]: (state, { payload }) => {
            state.studentAdded = !state.studentAdded;
            state.isFetching = false;
            return state;
        },
        [addStudentToList.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [addStudentToList.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [getAllStudentsFromRecord.fulfilled]: (state, { payload }) => {
            console.log("printing post payload", payload);
            state.studentsLists = payload;
            state.isFetching = false;
            return state;
        },
        [getAllStudentsFromRecord.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllStudentsFromRecord.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
        [updateStudentData.fulfilled]: (state, { payload }) => {
            state.updated = !state.updated;
            state.isFetching = false;
            return state;
        },
        [updateStudentData.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [updateStudentData.pending]: (state) => {
            // console.log("inside pending state", state);
            state.isFetching = true;
        },
    },
});

export const { clearState } = emailSlice.actions;
export const emailData = (state) => state.emailAuto;
