
export const environment = {
  production: true,
  // baseURL: "https://mu-react-backend.herokuapp.com",
  // baseURL: "https://api-react-staging.mastersunion.org", // For Testing
  // baseURL: "https://apireact.mastersunion.org", // For Live
  // baseURL: "https://email-automation-backend-production.up.railway.app",
  // baseURL: "http://localhost:3233",
  baseURL: "https://email-automation-services.onrender.com"
};
