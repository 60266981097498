import { configureStore } from "@reduxjs/toolkit";

import { emailSlice } from "../emailSlice";
import { userSlice } from "../userSlice";

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    emailAuto: emailSlice.reducer
  },
});
 