import React, { useEffect, useState } from "react";
import "./headerStyle.css";
import { useNavigate } from "react-router-dom";
import PrimaryButtonImg from "../buttons/primaryButtonImg/PrimaryButtonImg";

const Header = () => {
  // console.log("printing props", props);
  const [isUserLoggedIn, setUserLoggedIn] = useState(false);
  let navigate = useNavigate();
  // const pathname = window.location.pathname;
  // let position = pathname.search("feedbackPreview");

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    if (isLoggedIn) {
      setUserLoggedIn(isLoggedIn);
    }
  });

  // console.log("gfgfgfg", position);
  const logout = () => {
    // e.preventDefault();
    localStorage.clear();
    navigate("/login", { replace: true });
    setUserLoggedIn(false)
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
    // history.replace("/login");
  };


  return (<>
    {isUserLoggedIn &&
      (<div className="pageHeader topHeader">
       
          <img src="/assets/img/egronLogo.png" alt="" className="mainLogo" onClick={() => navigate('/')} style={{cursor: "pointer"}}/>
        
        <div className="pageTogglers">
          <div className="pageToggler active">
          </div>
        </div>
        <div>
          <div className="pageHeaderRight">
            <p className="logOutText" onClick={logout}>Log Out</p>
            <PrimaryButtonImg classname={'primaryBtn'} children={'Compose'} img={'../assets/icons/compose.svg'} onClick={() => navigate('/createRecord')} />
          </div>

        </div>
      </div>)
    }
  </>
  );
};

export default Header;
