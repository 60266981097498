import React, { useState } from 'react'
import './CheckboxStyle.css'
const Checkbox = ({ label, value, onChange, checked }) => {

    return (
        <>
            <div className="checkmarkContainerOuter">
                <label className="checkboxContainer">
                    <input type="checkbox" checked={checked} value={value} onChange={onChange}/>
                    <span className="checkmark"></span>
                    <span>{label}</span>
                </label>
            </div>
        </>
    );
};

export default Checkbox;
