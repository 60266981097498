import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import RecordList from './pages/records/RecordList'
import './App.css'
import CreateRecord from "./pages/createRecord/CreateRecord";
import { PrivateRoute } from "./helpers/PrivateRoute";
import ListData from "./pages/list data/ListData";
import Header from "./components/globalComponents/header/header";
import LandingPage from "./pages/landingPage/LandingPage";


function App() {
  return (
    <>
      <Header />

      <div className="appOuter">
          <Routes>
            <Route exact path="/login" element={<Login />} />

            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/" element={<LandingPage />} />
              <Route exact path="/recordList" element={<RecordList />} />
              <Route exact path="/listData/:listId" element={<ListData />} />
              <Route exact path="/createRecord" element={<CreateRecord />} />
            </Route>
          </Routes>
      </div>
    </>
  );
}

export default App;
