export const COMMON_TEXT = {
  loginText: "Login",
  forgetText: "Submit",
  resetText: "Confirm",
  forgotPassword: "Forget Password?",
  keepLogin: "Remember my login details",
  passwordAlert: "Please enter password",
  enterPassword: "Enter Password",
  emailAlert: "Please enter valid email",
  enterEmail: "Enter Email",
  welcomeAdmin: "Welcome",
  loginToAccount: "Please login to your Account",
  forgetAdmin: "User forget password",
  forgetAdminText: "Please your Email",
  backToLogin: "Back to Login",
  resetAdmin: "Reset your Password",
  resetAdminText: "Please Reset your Password",
  enterCnfPassword: "Enter Comfirm Password",
};