import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearState, emailData, getAllStudentsByList, getAllStudentsByUser, updateListStudents, updateStudentData } from '../../../reducers/emailSlice'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import Checkbox from '../../globalComponents/checkBox/checkBox'
import TextField from '../../globalComponents/textFields/TextField'

const EditEmailListPopup = ({ onClose, listData, currListId, setCurrListId, setIsAddStdOpen, onToggle }) => {

  const { students, studentsList,updated } = useSelector(emailData);
  const [stdIds, setStdIds] = useState([]);
  const [editId, setEditId] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getAllStudentsByUser());
    dispatch(clearState());
  }, [updated])

  useEffect(() => {
    if (currListId !== null && currListId !== '') {
      dispatch(getAllStudentsByList(currListId))
    }
  }, [currListId])

  useEffect(() => {
    setStdIds(studentsList.map(s => s._id))
  }, [studentsList])



  const onSelectAll = () => {
    if (studentsList.every(i => stdIds.includes(i._id))) {
      setStdIds([]);
    } else {
      setStdIds(studentsList.map(s => s._id))
    }
  }

  const studentIdHandler = (e) => {
    let id = e.target.value;
    if (stdIds.includes(id)) {
      setStdIds(stdIds.filter((checked_id) => checked_id !== id));
    } else {
      stdIds.push(id);
      setStdIds([...stdIds]);
    }
  }

  const onSave = () => {
    if (currListId) {
      dispatch(updateListStudents({ stdIds, listId: currListId }))
    }
  }

  const updateStd = (stdId) => {
    if(name !='' && email != '' && phone!= ''){
      console.log({email,name,phone}, stdId);
      dispatch(updateStudentData({obj:{email,name,phone} ,stdId}));
      setEditId(null);
    }
  }

  return (
    <div className="popupOuter active">
      <div className="editEmailListContainer">
        <div className="popupHeader">
          <p className="popupTitle">Edit Email list </p>
          <img src="/assets/icons/closeWhite.svg" alt="" onClick={onClose} />
        </div>
        <div className="popupBody">
          {currListId == null ? <div className="feedbackTableContainer">
            <table className="feedbackTable" cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th>User list</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {listData.map((lst) => (
                  <tr key={lst._id}>
                    <td>{lst.name}</td>
                    <td>
                      <div className="listName" onClick={() => { setCurrListId(lst._id); dispatch(getAllStudentsByList(lst._id)) }}>
                        <p className="primaryText">View</p>
                        <img src="../assets/icons/arrow-right.svg" alt="" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> :
            <div className="feedbackTableContainer">
              <table className="feedbackTable" cellPadding={0} cellSpacing={0}>
                <thead>
                  <tr>
                    <th>
                      <Checkbox label={'member list'} checked={studentsList.every(i => stdIds.includes(i._id))}
                        onChange={onSelectAll} />
                    </th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {studentsList.map((std) => (
                    editId == std._id ?
                      <tr key={std._id}>
                        <td>
                          <TextField  placeholder={'Name'}  isImportant={true} defaultValue={std.name} onChange={(e) => setName(e.target.value)} />
                        </td>
                        <td>
                          <TextField placeholder={'Email'} isImportant={true} defaultValue={std.email} onChange={(e) => setEmail(e.target.value)} />
                        </td>
                        <td>
                          <TextField placeholder={'Phone'} isImportant={true} defaultValue={std.phone} onChange={(e) => setPhone(e.target.value)} />
                        </td>
                        <td><PrimaryButton classname={'primaryBtn'} children={'Save'} onClick={() => updateStd(std._id)} /></td>
                      </tr> :
                      <tr key={std._id}>
                        <td>
                          <Checkbox label={std.name} value={std._id}
                            checked={stdIds.includes(std._id)} onChange={studentIdHandler} />
                        </td>
                        <td>{std.email}</td>
                        <td>{std.phone}</td>
                        <td><PrimaryButton classname={'primaryBtn'} children={'Edit'} onClick={() => {setEditId(std._id); setName(std.name);
                           setEmail(std.email); setPhone(std.phone); }}/></td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }

          {currListId !== null && <div className="popupFooter mt-20">
            <PrimaryButton classname={'primaryBtn'} children={'Add Member'} onClick={() => { setIsAddStdOpen(); onToggle(); }} />
            <PrimaryButton classname={'lightOutlineBtn'} children={'Cancel'} onClick={onClose} />
            <PrimaryButton classname={'primaryBtn'} children={'Save'} onClick={onSave} />
          </div>}
        </div>
      </div>
    </div>
  )
}

export default EditEmailListPopup